<template>
  <b-row>
    <b-col
      cols="12"
      md="6"
    >
      <b-card title="Common">
        <b-card-text>{{ $t('message.no_ability_required') }}</b-card-text>
        <b-card-text class="text-primary">
          {{ $t('message.card_visible_to_user_and_admin') }}
        </b-card-text>
      </b-card>
    </b-col>
    <b-col
      v-if="$can('read', 'Analytics')"
      cols="12"
      md="6"
    >
      <b-card title="Analytics">
        <b-card-text>{{ $t('message.user_with_analytics') }}</b-card-text>
        <b-card-text class="text-danger">
          {{ $t('message.card_visible_to_admin') }}
        </b-card-text>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BRow, BCol, BCard, BCardText,
  },
}
</script>

<style>

</style>
